// src/App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { getPlayer, getActivePlayersSorted } from './services/playerservice';
import HeaderComponent from './views/headercomponent'; // Adjust the path as needed
import HomePage from './pages/HomePage';
import RostershipPage from './pages/RostershipPage';
import PlayersPage from './pages/PlayersPage';
import FeedbackForm from './pages/Feedback';

function App() {
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  useEffect(() => {
    async function fetchPlayers() {
      try {
        const data = await getActivePlayersSorted();
        setPlayers(data);
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    }

    fetchPlayers();
  }, []);

  const handleRowClick = async (record) => {
    try {
      // Fetch detailed player info
      const playerInfo = await getPlayer(record.id);
      setSelectedPlayer(playerInfo);
    } catch (error) {
      console.error('Error fetching player info from here:', error);
    }
  };

  // Function to detect if the user is on a mobile device
  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

  return (
    <Router>
      <HeaderComponent isMobile={isMobile} /> {/* Pass isMobile prop here */}
      <div>
        <Routes>
          <Route path="/" element={<HomePage isMobile={isMobile} />} />
          <Route
            path="/players"
            element={(
              <PlayersPage 
                isMobile={isMobile}
                players={players}
                handleRowClick={handleRowClick}
                selectedPlayer={selectedPlayer}
                setSelectedPlayer={setSelectedPlayer}
              />
            )}
          />
          <Route path="/rostership" element={<RostershipPage isMobile={isMobile} />} />
          <Route path="/feedback" element={<FeedbackForm isMobile={isMobile} />} />
          <Route path="*" element={<h2>404 Not Found</h2>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
