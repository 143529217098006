// src/pages/HomePage.js

import React from 'react';
import './../css/homepage.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { TeamOutlined, SolutionOutlined } from '@ant-design/icons'; // Import Ant Design icons
import { faTwitter, faPatreon, faYoutube, faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HomePage = ({ isMobile }) => {
  return (
    <div className="homepage">
      {isMobile ? (
        <>
        <section className="welcome-mobile">
          <h1>Dynasty IMobile</h1>
          <div className="subtitle">
            <Link to="/players" className="no-underline">PLAYERS</Link> | <Link to="/rostership" className="no-underline">ROSTERSHIP</Link>
          </div>
        </section>

        <section className="additional-section-mobile">
          <Link to="/players" className="black-box-mobile no-underline">
            <TeamOutlined style={{ fontSize: '24px', marginBottom: '10px' }} />
            <h2>Players</h2>
            <p style={{ color: 'gray' }}>Explore and analyze dynasty players, including rankings and year-by-year performance metrics</p>
          </Link>
          <Link to="/rostership" className="black-box-mobile no-underline">
            <SolutionOutlined style={{ fontSize: '24px', marginBottom: '10px' }} />
            <h2>Rostership</h2>
            <p style={{ color: 'gray' }}>Manage your dynasty rostership efficiently, accessing detailed information about each team you own</p>
          </Link>
        </section>

        <section className="footer-mobile">
          <h2>Follow me on <span>all</span> socials right here</h2>
          <div className="social-links-mobile">
            <a href="https://twitter.com/dynasty_im" className="twitter-nobile" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} style={{ fontSize: '24px' }} /></a>
            <a href="https://patreon.com/dynastyIM" className="patreon" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faPatreon} style={{ fontSize: '24px' }} /></a>
            <a href="https://www.youtube.com/channel/UCB4Jf-CWYkcfbMzjFs13ZaA" className="youtube" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} style={{ fontSize: '24px' }} /></a>
            <a href="https://www.tiktok.com/@dynastyim" className="tiktok" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTiktok} style={{ fontSize: '24px' }} /></a>
            <a href="https://www.instagram.com/dynasty_im/" className="instagram" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} style={{ fontSize: '24px' }} /></a>
          </div>
        </section>
      </>
      ) : (
        <>
          <section className="welcome">
            <h1>Dynasty IM</h1>
            <div className="subtitle">
              <Link to="/players" className="no-underline">PLAYERS</Link> | <Link to="/rostership" className="no-underline">ROSTERSHIP</Link>
            </div>
          </section>

          <section className="additional-section">
            <Link to="/players" className="black-box no-underline">
              <TeamOutlined style={{ fontSize: '48px', marginBottom: '10px' }} />
              <h2>Players</h2>
              <p style={{ color: 'gray' }}>Explore and analyze dynasty players, including rankings and year-by-year performance metrics</p>
            </Link>
            <Link to="/rostership" className="black-box no-underline">
              <SolutionOutlined style={{ fontSize: '48px', marginBottom: '10px' }} />
              <h2>Rostership</h2>
              <p style={{ color: 'gray' }}>Manage your dynasty rostership efficiently, accessing detailed information about each team you own</p>
            </Link>
          </section>

          <section className="footer">
            <h2>Follow me on <span>all</span> socials right here</h2>
            <div className="social-links">
              <a href="https://twitter.com/dynasty_im" className="twitter" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} style={{ fontSize: '24px' }} /></a>
              <a href="https://patreon.com/dynastyIM" className="patreon" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faPatreon} style={{ fontSize: '24px' }} /></a>
              <a href="https://www.youtube.com/channel/UCB4Jf-CWYkcfbMzjFs13ZaA" className="youtube" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} style={{ fontSize: '24px' }} /></a>
              <a href="https://www.tiktok.com/@dynastyim" className="tiktok" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTiktok} style={{ fontSize: '24px' }} /></a>
              <a href="https://www.instagram.com/dynasty_im/" className="instagram" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} style={{ fontSize: '24px' }} /></a>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default HomePage;
