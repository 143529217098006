import React from 'react';
import PlayerCard from '../views/playercard';
import PlayerTable from '../views/playertable';
import './../css/playerspage.css';
import PlayerTableMobile from '../views/playertablemobile';
import PlayerCardMobile from '../views/playercardmobile';

function PlayersPage({ isMobile, players, handleRowClick, selectedPlayer, setSelectedPlayer }) {
    return (
        <div>
            {isMobile ? (
                <>
                    <div className="playerspage-mobile">
                        <div className='center2'>
                            Players
                        </div>
                        <div className={`player-card-container-mobile ${selectedPlayer ? '' : 'hidden'}`}>
                            {selectedPlayer && <PlayerCardMobile player={selectedPlayer} onClose={() => setSelectedPlayer(null)} setSelectedPlayer={setSelectedPlayer} />}
                        </div>
                        <div className="table-container">
                            <PlayerTableMobile data={players} onRowClick={handleRowClick} setSelectedPlayer={setSelectedPlayer} />
                        </div>
                    </div>

                </>
            ) : (
                <>
                    <div className="playerspage">
                        <div className='center2'>
                            Click on a player to see more information
                        </div>
                        <div className={`player-card-container ${selectedPlayer ? '' : 'hidden'}`}>
                            {selectedPlayer && <PlayerCard player={selectedPlayer} onClose={() => setSelectedPlayer(null)} setSelectedPlayer={setSelectedPlayer} />}
                        </div>
                        <div className="table-container">
                            <PlayerTable data={players} onRowClick={handleRowClick} setSelectedPlayer={setSelectedPlayer} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default PlayersPage;
