import React, { useState } from 'react';
import { Table, Button, Space, ConfigProvider, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './../css/playertable.css';

const PlayerTableMobile = ({ data, onRowClick }) => {
  const [pagination, setPagination] = useState({ pageSize: 50, current: 1 });
  const [searchText, setSearchText] = useState('');

  const handleChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const clearAll = () => {
    setSearchText('');
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };


  const filteredData = data.filter(item => 
    item.first_name.toLowerCase().includes(searchText.toLowerCase()) || 
    item.last_name.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'startuprank',
      key: 'startuprank',
      width: '13.9%',
      render: (text) => <span className="nowrap-rank">{text}</span>,
    },
    
    {
      title: 'Name',
      key: 'name',
      width: '27.5%',
      render: (text, record) => `${record.first_name.charAt(0)}. ${record.last_name}`,
    },

    {
      title: 'Player Tier',
      dataIndex: 'tier',
      key: 'tier',
      width: '30.7%',
      render: (text) => {
        let className = '';
        if (text === 'UNTOUCHABLE') {
          className = 'tier-UNTOUCHABLE';
        } else if (text === 'CORNERSTONE') {
          className = 'tier-CORNERSTONE';
        } else if (text === 'FOUNDATION') {
          className = 'tier-FOUNDATION';
        } else if (text === 'BASE') {
          className = 'tier-BASE';
        } else if (text === 'DEPTH') {
          className = 'tier-DEPTH';
        } else if (text === 'CLOGGER') {
          className = 'tier-CLOGGER';
        }
        return <span className={`${className} nowrap-tier`}>{text}</span>;
      },
    },
    

    {
      title: 'Player Type',
      dataIndex: 'type',
      key: 'type',
      width: '27.9%',
    },

  ];

  const tableStyle = {
    width: '90%',
    margin: '0 auto',
    marginTop: '20px',
    color: '#ffffff',
  };

  const handleRowClick = (record) => {
    onRowClick(record);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            fontSize: 10.5, // Set table font size here
            color: '#e91e63',
            backgroundColor: '#e91e63',
            borderColor: '#3f3f3f',
            headerBg: '#3f3f3f',
            headerColor: '#ffffff',
            darkItemSelectedBg: '#e91e63',
            darkItemHoverColor: '#e91e63',
            rowHoverBg: '#f83f7c',
            footerColor: '#ffffff',
            footerBg: '#ffffff'
          },
        },
      }}
    >
      <div style={tableStyle}>
        <Space style={{ marginBottom: 16 }}>
          <Input 
            placeholder="Search by name"
            value={searchText}
            onChange={handleSearch}
            suffix={<SearchOutlined />}
            style={{ width: 150 }}
          />
          <Button onClick={clearAll}>Clear search</Button>
        </Space>
        <Table
          size='small'
          columns={columns}
          dataSource={filteredData}
          onChange={handleChange}
          rowKey="id"
          pagination={{
            ...pagination,
            style: { paddingRight: '20px' }, // Add padding to the right
          }}          scroll={{ y: 300 }}
          rowClassName={(record, index) => `dark-mode-row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
          style={{ backgroundColor: '#3f3f3f' }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </div>
    </ConfigProvider>
  );
};

export default PlayerTableMobile;
