import React from 'react';
import './../css/playercardmobile.css'; // Ensure this CSS file is imported for styles
import './../css/tier.css';
import './../css/type.css';
import './../css/projections.css';
import './../css/buysellhold.css';
import './../App.css';
import calculateAge from '../utils/utils.js';
import PlayerMenu from './playermenu.tsx';
import PlayerImage from './playerimage.js';
import TeamMenu from './teammenu.tsx';
import StatsTable from './statstable.js';
import CustomTabs from './customtabs.js'; // Import the custom Tabs component
import TabPane from 'antd/es/tabs/TabPane';
import { Button } from "antd";
import { CloseOutlined } from '@ant-design/icons';

const PlayerCardMobile = ({ player, onClose, setSelectedPlayer }) => {
  const getBuySellHoldClass = (buysellhold) => {
    switch (buysellhold) {
      case 'BUY':
        return 'buy';
      case 'SELL':
        return 'sell';
      case 'HOLD':
      default:
        return 'hold';
    }
  };

  const player_info = player.player_info;
  const process_stats = player.process_stats[0];
  const projections = player.projections[0];
  const stats = player.stats;

  const age = calculateAge(player_info.birthday);


  return (

    <div className='card-mobile'>
      <div className="button-tab-container-mobile">
        <Button
          type="primary"
          shape="circle"
          icon={<CloseOutlined style={{ color: 'black' }} />}
          className="overlay-button"
          onClick={onClose}
        />
        <CustomTabs defaultActiveKey="1" width='90%'>
          <TabPane tab="Player Info" key="1">
            {/* <div className='sectional-big-mobile'>
              <TeamMenu currentTeam={player_info.team} setSelectedPlayer={setSelectedPlayer} />
            </div> */}
            <div className="content-wrapper-mobile">
              <div style={{width: '100%'}}>
                <div className="column-and-tall-wrapper-mobile">
                  <div className="column-container-mobile">
                    <div className="column-mobile">
                      {/* <div className='sectional-small-mobile'>
                        <PlayerMenu firstName={player_info.first_name} lastName={player_info.last_name} position={player_info.position} team={player_info.team} setSelectedPlayer={setSelectedPlayer} />
                      </div> */}
                      <div className="centered-wrapper-mobile">
                        <div className="centered-player-info-mobile">
                          <div className="player-info-mobile">
                            <h1 className="position-mobile">{process_stats.rank}</h1>
                            <h1>{player_info.first_name}</h1>
                          </div>
                          <div className="player-info-mobile">
                            <h1 className="position-mobile">{process_stats.startuprank}</h1>
                            <h1>{player_info.last_name}</h1>
                          </div>
                        </div>
                      </div>
                      <div className="tier-mobile">
                        <h1 className={`tier-mobile ${process_stats.tier.toLowerCase()}`}>
                          <span>{process_stats.tier ? process_stats.tier.toUpperCase() : ''}</span>
                        </h1>
                      </div>

                      <div className="type-mobile">
                        <h1 className={`type-mobile ${process_stats.type.toLowerCase()}`}>{process_stats.type ? process_stats.type.toUpperCase() : ''}</h1>
                      </div>
                    </div>

                    {/* <div className="column-mobile">
                      <div className="general-info-mobile">
                        <div className="info-row-mobile">
                          <span className="label-mobile">BORN</span>
                          <span className="value-mobile">{player_info.birthday}</span>
                        </div>
                        <div className="info-row-mobile">
                          <span className="label-mobile">AGE</span>
                          <span className="value-mobile">{age}</span>
                        </div>
                        <div className="info-row-mobile">
                          <span className="label-mobile">COLLEGE</span>
                          <span className="value-mobile">{player_info.college}</span>
                        </div>
                        <div className="info-row-mobile">
                          <span className="label-mobile">DRAFT YEAR</span>
                          <span className="value-mobile">{player_info.draftyear}</span>
                        </div>
                      </div>
                      
                    </div> */}
                  </div>
                </div>
                <div className="player-info2-mobile">
                  <div className="info-pair-mobile">
                    <div className='buildtype-mobile'>
                      REBUILDER
                    </div>
                    <h1 className={`buysellhold-mobile ${getBuySellHoldClass(process_stats.rebuilderbsh)}`}>{process_stats.rebuilderbsh ? process_stats.rebuilderbsh : 'HOLD'}</h1>
                  </div>
                  <div className="info-pair-mobile">
                    <div className='buildtype-mobile'>
                      CONTENDER
                    </div>
                    <h1 className={`buysellhold-mobile ${getBuySellHoldClass(process_stats.contenderbsh)}`}>{process_stats.contenderbsh ? process_stats.contenderbsh : 'HOLD'}</h1>
                  </div>
                </div>
                <div className="stats-table-wrapper-mobile">
                  <StatsTable position={player_info.position} stats={stats} />
                </div>
              </div>
              {/* <div className="tall-mobile">
                <PlayerImage player={player} player_info={player_info} />
              </div> */}
            </div>
          </TabPane>
        </CustomTabs>
      </div>
    </div>

    // <div className="player-card-mobile">
    //   <div className="button-tab-container-mobile">
    //     <Button
    //       type="primary"
    //       shape="circle"
    //       icon={<CloseOutlined style={{ color: 'black' }} />}
    //       className="overlay-button"
    //       onClick={onClose}
    //     />
    //     <CustomTabs defaultActiveKey="1" width='90%'>
    //       <TabPane tab="Player Info" key="1">
    //         <div className='sectional-big-mobile'>
    //           <TeamMenu currentTeam={player_info.team} setSelectedPlayer={setSelectedPlayer} />
    //         </div>
    //         <div className="content-wrapper-mobile">
    //           <div style={{ width: '844px' }}>
    //             {/* <div className="column-and-tall-wrapper-mobile">
    //               <div className="column-container-mobile">
    //                 <div className="column-mobile">
    //                   <div className='sectional-small-mobile'>
    //                     <PlayerMenu firstName={player_info.first_name} lastName={player_info.last_name} position={player_info.position} team={player_info.team} setSelectedPlayer={setSelectedPlayer} />
    //                   </div>
    //                   <div className="centered-wrapper-mobile">
    //                     <div className="centered-player-info-mobile">
    //                       <div className="player-info-mobile">
    //                         <h1 className="position-mobile">{process_stats.rank}</h1>
    //                         <h1>{player_info.first_name}</h1>
    //                       </div>
    //                       <div className="player-info-mobile">
    //                         <h1 className="position-mobile">{process_stats.startuprank}</h1>
    //                         <h1>{player_info.last_name}</h1>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="tier-mobile" style={{ paddingTop: '20px', marginBottom: '-20px', marginLeft: '-15px', marginTop: '-20px', width: "100%" }}>
    //                     <h1 className={`tier-mobile ${process_stats.tier.toLowerCase()}`}>{process_stats.tier ? process_stats.tier.toUpperCase() : ''}</h1>
    //                   </div>
    //                   <div className="type-mobile" style={{ marginLeft: '-15px', width: "100%" }}>
    //                     <h1 className={`type-mobile ${process_stats.type.toLowerCase()}`}>{process_stats.type ? process_stats.type.toUpperCase() : ''}</h1>
    //                   </div>
    //                 </div>
    //                 <div className="column-mobile">
    //                   <div className="general-info-mobile">
    //                     <div className="info-row-mobile">
    //                       <span className="label-mobile">BORN</span>
    //                       <span className="value-mobile">{player_info.birthday}</span>
    //                     </div>
    //                     <div className="info-row-mobile">
    //                       <span className="label-mobile">AGE</span>
    //                       <span className="value-mobile">{age}</span>
    //                     </div>
    //                     <div className="info-row-mobile">
    //                       <span className="label-mobile">COLLEGE</span>
    //                       <span className="value-mobile">{player_info.college}</span>
    //                     </div>
    //                     <div className="info-row-mobile">
    //                       <span className="label-mobile">DRAFT YEAR</span>
    //                       <span className="value-mobile">{player_info.draftyear}</span>
    //                     </div>
    //                   </div>
    //                   <div className="player-info2-mobile">
    //                     <div className="info-pair-mobile">
    //                       <div className='buildtype-mobile'>
    //                         REBUILDER
    //                       </div>
    //                       <h1 className={`buysellhold-mobile ${getBuySellHoldClass(process_stats.rebuilderbsh)}`}>{process_stats.rebuilderbsh ? process_stats.rebuilderbsh : 'HOLD'}</h1>
    //                     </div>
    //                     <div className="info-pair-mobile">
    //                       <div className='buildtype-mobile'>
    //                         CONTENDER
    //                       </div>
    //                       <h1 className={`buysellhold-mobile ${getBuySellHoldClass(process_stats.contenderbsh)}`}>{process_stats.contenderbsh ? process_stats.contenderbsh : 'HOLD'}</h1>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div> */}
    //             <div className="stats-table-wrapper-mobile">
    //               <StatsTable position={player_info.position} stats={stats} />
    //             </div>
    //           </div>
    //           <div className="tall-mobile">
    //             <PlayerImage player={player} player_info={player_info} />
    //           </div>
    //         </div>
    //       </TabPane>
    //       {/* <TabPane tab="Projections" key="2">
    //         <div className="projections">
    //           <h2>2024 Projections</h2>
    //           <h2> 6pt Pass TD --- PPR --- +0.5 TE Premium</h2>
    //           <div className="projection-container">
    //             <div className="projection1">
    //               <p className="label">MED</p>
    //               {projections && projections.medproj && <p className="value">{projections.medproj}</p>}
    //             </div>
    //             <div className="projection2">
    //               <p className="label">HIGH</p>
    //               {projections && projections.highproj && <p className="value">{projections.highproj}</p>}
    //             </div>
    //             <div className="projection1">
    //               <p className="label">LOW</p>
    //               {projections && projections.lowproj && <p className="value">{projections.lowproj}</p>}
    //             </div>
    //           </div>
    //         </div>
    //       </TabPane>
    //       <TabPane tab="ROO" key="3">
    //         <div style={{ color: 'white', fontSize: '25px', fontFamily: 'Briston' }}>
    //           Coming Soon...
    //         </div>
    //       </TabPane> */}
    //     </CustomTabs>
    //   </div>
    // </div>
  );
};

export default PlayerCardMobile;
